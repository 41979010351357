<template>
  <div class="max-w-[96rem] mx-auto">
    <st-title
      :navItems="[
        {
          name: $t('studio.prj_prod_mngmt.prj_reg.breadcrumb1'),
          url: 'projects'
        },
        {
          name: $t('studio.prj_prod_mngmt.prj_reg.breadcrumb2')
        }
      ]"
      :title="$t('studio.prj_prod_mngmt.prj_reg.pg_title')"
    />
    <div class="flex gap-24 mt-40">
      <div class="flex-1">
        <st-box>
          <st-form-title required :formTitle="$t('studio.prj_prod_mngmt.prj_reg.prj_name')" />
          <input-text
            name="projectName"
            :placeholder="$t('studio.prj_prod_mngmt.prj_reg.prj_name_place_holder')"
            maxLength="60"
            :rules="{
              required: {
                value: true,
                message: $t('studio.prj_prod_mngmt.prj_reg.prj_name_val_msg1')
              },
              max_length: 60
              // regex: {
              //   regex: REGEX_PRODUCT_NAME,
              //   message: $t('studio.common.def_key.special_character_n')
              // },
            }"
          />

          <div class="mt-24 pt-24 border-t-1 border-solid border-border">
            <checkbox
              id="chk-01"
              v-model="registerProducts"
              :options="{
                size: 'sm',
                align: 'middle'
              }"
            >
              <span class="leading-lg text-on-surface-elevation-1">
                {{ $t('studio.prj_prod_mngmt.prj_reg.chkbox_add_prod') }}
              </span>
            </checkbox>
          </div>
          <template v-if="registerProducts">
            <st-form-title
              :formTitle="$t('studio.prj_prod_mngmt.prj_reg.prod_name')"
              class="mt-24"
              required
            />
            <input-text
              :placeholder="$t('studio.prj_prod_mngmt.prj_reg.prod_name_place_holder')"
              name="productOriginalName"
              maxLength="50"
              :rules="{
                required: {
                  value: true,
                  message: $t('studio.prj_prod_mngmt.prj_reg.prod_name_val_msg1')
                },
                max_length: 50,
                regex: {
                  regex: REGEX_PRODUCT_NAME,
                  message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_invalid')
                }
              }"
            />
            <st-form-title
              :formTitle="$t('studio.prj_prod_mngmt.prj_reg.prod_type')"
              class="mt-24"
              required
            />
            <p class="text-xs leading-xs text-placeholder">
              {{ $t('studio.prj_prod_mngmt.prj_reg.prod_type_guide') }}
            </p>
            <div class="relative mt-4">
              <dropdown
                name="productOriginalType"
                :options="items"
                :dropdownProps="{
                  size: 'lg',
                  variant: 'line',
                  distance: 4,
                  offset: [0, 0],
                  placement: 'bottom',
                  class: 'w-full'
                }"
                :rules="{
                  required: {
                    value: true,
                    message: $t('studio.prj_prod_mngmt.prj_reg.prod_type_val_msg')
                  }
                }"
                closeOnClick
                dropdownBtnClass="w-full"
                :placeholder="$t('studio.prj_prod_mngmt.prj_reg.dropbox_prod_type')"
              />
            </div>
            <div class="flex items-center gap-16 mt-24">
              <st-form-title
                :formTitle="$t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.title')"
                class="mt-4"
              />
              <s-switch
                id="chk-02"
                v-model="registerBulk"
                size="md"
                @update:modelValue="handleChangeBulk"
              />
            </div>
            <div v-show="registerBulk">
              <st-form-title
                :formTitle="$t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.prod_num')"
                class="mt-24"
                required
              />
              <p class="text-xs leading-xs text-placeholder">
                {{ $t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.guide1') }}
              </p>
              <p class="mt-4 text-xs leading-xs text-brand-primary">
                {{ $t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.guide2') }}
              </p>
              <div class="mt-4">
                <input-text
                  name="numberOfProducts"
                  :placeholder="
                    $t(
                      'studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.prod_num_input_place_holder'
                    )
                  "
                  :rules="productBulkRules"
                />
              </div>
            </div>
          </template>
        </st-box>
        <div class="mt-40 flex justify-center gap-16">
          <s-button
            :isDisabled="isDisabled"
            variant="primary"
            size="lg"
            class="!min-w-160"
            @click="onSubmit"
          >
            {{ $t('studio.prj_prod_mngmt.prj_reg.btn_reg') }}
          </s-button>
        </div>
      </div>
      <right-wing>
        <right-wing-item
          :rightWingTitle="$t('studio.prj_prod_mngmt.prj_reg.prj_guide1')"
          numberValue="01"
        >
          <safe-html as="span" :html="$t('studio.prj_prod_mngmt.prj_reg.prj_guide2')" />
        </right-wing-item>
        <div class="mt-8">
          <img :src="projectImageSrc" alt="" class="w-full block" />
        </div>
        <template v-if="registerProducts">
          <right-wing-item
            :rightWingTitle="$t('studio.prj_prod_mngmt.prj_reg.prod_guide1')"
            numberValue="02"
            class="mt-24"
          >
            <safe-html as="span" :html="$t('studio.prj_prod_mngmt.prj_reg.prod_guide2')" />
          </right-wing-item>
          <right-wing-item
            :rightWingTitle="$t('studio.prj_prod_mngmt.prj_reg.prod_type_guide1')"
            numberValue="03"
            class="mt-24"
          >
            {{ $t('studio.prj_prod_mngmt.prj_reg.prod_type_guide2') }}
          </right-wing-item>
          <ul class="mt-4 text-sm leading-md text-on-surface-elevation-3">
            <li
              class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
            >
              <strong class="font-medium text-on-surface-elevation-2">Game</strong>
              - {{ $t('studio.prj_prod_mngmt.prj_reg.prod_type_guide3') }}
            </li>
            <!-- <li
              class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
            >
              <strong class="font-medium text-on-surface-elevation-2">Content</strong>
              - {{ $t('studio.prj_prod_mngmt.prj_reg.prod_type_guide4') }}
            </li> -->
            <li
              class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
            >
              <strong class="font-medium text-on-surface-elevation-2">Utility</strong>
              - {{ $t('studio.prj_prod_mngmt.prj_reg.prod_type_guide5') }}
            </li>
          </ul>
          <p class="mt-8 text-sm leading-md text-on-surface-elevation-3">
            {{ $t('studio.prj_prod_mngmt.prj_reg.prod_type_guide6') }}
          </p>
        </template>
      </right-wing>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldError, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';

import {
  checkProductInProjectNameApi,
  checkProjectProductName,
  checkRegisterProjectProductApi,
  createProjectApi,
  createProjectBulkProductsApi
} from '@/apis/project-product.api';
import * as ProjectImages from '@/assets/images/project/project-guide/index';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import StTitle from '@/components/common/st-title.vue';
import AddProductMassDialog from '@/components/project-product/add-product-mass-dialog.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { showDialog } from '@/composables/useDialog';
import { STATUS_CODE } from '@/constants/error.const';
import { PRODUCT_TYPES } from '@/constants/project-product.const';
import { REGEX_PRODUCT_NAME } from '@/constants/regex.const';
import { PROJECT_MNG_PAGE_URL } from '@/constants/url.const';
import { RuleNames } from '@/enums/validation.enum';
import { useAppStore } from '@/stores/app.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type {
  CreateProjectProductRequest,
  ProductModel,
  ProjectProductFormModel
} from '@/types/project-product/project-product-model.type';
import { throwContentError } from '@/utils/api-error.util';
import { redirectTo, showConfirmLeaveDialog } from '@/utils/common.util';
import { generateErrorMsg } from '@/utils/validation.util';

const app = useApp();
const { t, locale } = useI18n();

const projectImages = ref<Record<string, string>>(ProjectImages);
const projectImageSrc = computed(
  () => projectImages.value[`ProjectGuide_${locale.value.replace('-', '_')}`]
);

const { handleSubmit } = useForm<ProjectProductFormModel>({
  initialValues: {
    projectName: '',
    productOriginalName: '',
    productOriginalType: '',
    numberOfProducts: 2
  }
});
const numberOfProducts = useFieldValue<number>('numberOfProducts');
const setNumberOfProducts = useSetFieldValue('numberOfProducts');
const productOriginalName = useFieldValue<string>('productOriginalName');
const productOriginalType = useFieldValue<string>('productOriginalType');
const setProjectNameError = useSetFieldError('projectName');
const setProductNameError = useSetFieldError('productOriginalName');

const isCheckLeave = ref<boolean>(true);

const items: FormOption[] = PRODUCT_TYPES;

const registerBulk = ref<boolean>(false);
const registerProducts = ref<boolean>(false);

const { isLoading } = storeToRefs(useAppStore());
const forceDisable = ref<boolean>(false);
const isDisabled = computed(() => isLoading.value || forceDisable.value);

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const productBulkRules = computed(() => {
  if (!registerBulk.value) {
    return {};
  }
  return {
    required: {
      value: true,
      message: t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.prod_num_input_val_msg')
    },
    number: t('studio.common.def_key.number_only_y'),
    gt: {
      value: 1,
      message: t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.prod_num_input_val_msg2')
    },
    lte: {
      value: 50,
      message: t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.prod_num_input_val_msg2')
    }
  };
});

const handleChangeBulk = (value: boolean) => {
  if (value) {
    setNumberOfProducts(2);
  }
};

const registerProjectWithNoProduct = async (value: ProjectProductFormModel) => {
  const createRequest: CreateProjectProductRequest = {
    groupId: selectedGroupId.value,
    projectName: value.projectName.trim()
  };

  const createResult = await createProjectApi(createRequest);
  if (createResult) {
    isCheckLeave.value = false;
    await redirectTo(PROJECT_MNG_PAGE_URL);
  }
};

const registerProjectWithSingleProduct = async (value: ProjectProductFormModel) => {
  const createRequest: CreateProjectProductRequest = {
    groupId: selectedGroupId.value,
    projectName: value.projectName.trim(),
    product: {
      productName: value.productOriginalName.trim(),
      productType: value.productOriginalType
    }
  };
  const createResult = await createProjectApi(createRequest);

  if (createResult) {
    isCheckLeave.value = false;
    await redirectTo(PROJECT_MNG_PAGE_URL);
  }
};

const registerProjectWithBulkProducts = async (value: ProjectProductFormModel) => {
  const result = await showDialog<ProductModel[]>({
    component: shallowRef(AddProductMassDialog),
    props: {
      numberOfProducts: numberOfProducts.value,
      productOriginal: {
        productName: productOriginalName.value.trim(),
        productType: productOriginalType.value
      },
      groupId: selectedGroupId.value,
      projectName: value.projectName.trim()
    },
    severity: 'info'
  });

  if (result.length === 0) {
    return;
  }

  const createResult = await createProjectBulkProductsApi({
    groupId: selectedGroupId.value,
    projectName: value.projectName.trim(),
    products: result.map((item: ProductModel) => ({
      productName: item.productName.trim(),
      productType: item.productType
    }))
  });

  if (createResult) {
    isCheckLeave.value = false;
    await redirectTo(PROJECT_MNG_PAGE_URL);
  }
};

const onSubmit = handleSubmit(async (value: ProjectProductFormModel) => {
  forceDisable.value = true;

  if (!(await app.checkMenuWritable())) {
    forceDisable.value = false;
    return;
  }

  try {
    const checkRegisterProjectPossible = await checkRegisterProjectProductApi({
      groupId: selectedGroupId.value
    });
    if (!checkRegisterProjectPossible?.success) {
      return;
    }

    const checkProjectName = await checkProjectProductName({
      groupId: selectedGroupId.value,
      projectName: value.projectName.trim()
    });
    if (!checkProjectName?.success) {
      return;
    }

    if (!registerProducts.value) {
      await registerProjectWithNoProduct(value);
      return;
    }

    const checkProdName = await checkProductInProjectNameApi({
      groupId: selectedGroupId.value,
      productName: value.productOriginalName.trim(),
      languageCd: 'ALL'
    });
    if (!checkProdName?.success) {
      return;
    }

    if (!registerBulk.value) {
      await registerProjectWithSingleProduct(value);
      return;
    }

    await registerProjectWithBulkProducts(value);
    forceDisable.value = false;
  } catch (e: unknown) {
    const error = e as ErrorResponse;
    forceDisable.value = false;
    if (error.statusCode === STATUS_CODE.PROJECT_MAX_EXISTED) {
      const content = `${t('studio.prj_prod_mngmt.prj_reg.prj_num_over100_val_msg1')}<br/>${t(
        'studio.prj_prod_mngmt.prj_reg.prj_num_over100_val_msg2'
      )}`;

      await throwContentError(content);
      return;
    }
    if (error.statusCode === STATUS_CODE.PROJECT_NAME_DUPLICATED) {
      setProjectNameError(
        generateErrorMsg(
          'studio.prj_prod_mngmt.prj_reg.prj_name_val_msg3',
          RuleNames.CHECK_DUPLICATE
        )
      );
      return;
    }
    if (error.statusCode === STATUS_CODE.PRODUCT_NAME_DUPLICATED) {
      setProductNameError(
        generateErrorMsg(
          'studio.prj_prod_mngmt.prj_reg.prod_name_val_msg3',
          RuleNames.CHECK_DUPLICATE
        )
      );
    }
  }
});

onBeforeRouteLeave(async () => {
  if (isCheckLeave.value) {
    return showConfirmLeaveDialog();
  }
});
</script>
